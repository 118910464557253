import React, { useEffect } from 'react';
import { cx, css } from '@linaria/core';
import { maxWidthStyles } from '../styles/maxWidthStyles';
import 'smoothscroll-anchor-polyfill';
import '../styles/global/variables.css';
import '../../../../../stylesheets/fonts.css';
import '../../../../../stylesheets/global.css';
import '../styles/global/typography.css';
import 'normalize.css';
import * as smoothscroll from 'smoothscroll-polyfill';
import { reducedMotionUserSetting } from '../../../utilities/helpers';

// enable smoothscrolling on all browsers
if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

const MainWrapper = props => {
  useEffect(() => {
    const reducedMotion = reducedMotionUserSetting();
    if (reducedMotion) {
      document.documentElement.style.scrollBehavior = 'auto';
    }
  }, []);

  return (
    <div className={cx(props.className, 'main', styles)} id={props.id} {...props} >
      {props.children}
    </div>
  );
}

const styles = css`
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;
  position: relative;
  .main&:not(#review-container):not(#footer-container):not(#seo-report-card-container) {
    ${maxWidthStyles};
  }
`;

export default MainWrapper;
