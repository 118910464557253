import React from 'react';
import { styled } from '@linaria/react';
import { CSSTransition } from 'react-transition-group';
import animationStyles from './animationStyles';
import { Link, BoldLink } from '../StyledComponents';

class PopularCategories extends React.Component {
  state = { showMenu: false }

  getInitialExpanedState = () => {
    return Object.keys(this.props.popularCategories).reduce((coll, next, i) => {
      coll[`itemExpanded${i+1}`] = false;
      return coll;
    }, {});
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMouseClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseClick, false);
  }

  handleMouseClick = e => {
    if (this.state.showMenu) {
      const root = document.getElementById('popular-cats-dropdown-menu-panel');
      const link = document.getElementById('expand-popular-categories-link-menu-panel');
      if (root.contains(e.target) || link.contains(e.target)) {
        return null;
      }
      this.handleClickOutside();
    }
  }

  handleClickOutside = () => this.setState({ showMenu: false, ...this.getInitialExpanedState() });

  toggleMenu = () => this.setState({ showMenu: !this.state.showMenu, ...this.getInitialExpanedState() });

  toggleSubMenu = stateKey => this.setState({ [stateKey]: !this.state[stateKey] });

  render() {
    const { popularCategories } = this.props;
    const { showMenu } = this.state;
    const reducedMotionClass = this.props.reducedMotion ? 'reduced-motion' : null;
    const categoryKeys = Object.keys(popularCategories || {});

    return (
      <>
        <BoldLink
          id='expand-popular-categories-link-menu-panel'
          className='tactile-animation'
          onClick={this.toggleMenu}
          tabIndex='0'
        >
          Other Popular Categories {showMenu ? '-' : '+'}
        </BoldLink>
        <AccordionWrapper>
          <CSSTransition in={showMenu} timeout={300} classNames={`${reducedMotionClass} expanded`}>
            <TransitionStyles id='popular-cats-dropdown-menu-panel'>
              {categoryKeys.map((key, i) => {
                const stateKey = `itemExpanded${i+1}`;
                const isExpanded = this.state[stateKey];
                return (
                  <React.Fragment key={key}>
                    <Link
                      className='tactile-animation'
                      onClick={this.toggleSubMenu.bind(null, stateKey)}
                      tabIndex='0'
                    >
                      {key} {isExpanded ? '-' : '+'}
                    </Link>
                    <AccordionWrapper>
                      <CSSTransition in={isExpanded} timeout={300} classNames={`${reducedMotionClass} expanded`}>
                        <TransitionStyles>
                          {popularCategories[key].map(({ service_group_name, list_slug }) => (
                            <Link
                              key={list_slug}
                              tabIndex='0'
                              href={list_slug}
                              margin='0 0 0 25px'
                            >
                              {service_group_name}
                            </Link>
                          ))}
                        </TransitionStyles>
                      </CSSTransition>
                    </AccordionWrapper>
                  </React.Fragment>
                )})}
            </TransitionStyles>
          </CSSTransition>
        </AccordionWrapper>
      </>
    );
  }
}

export default PopularCategories;


// partials

const AccordionWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const TransitionStyles = styled.div`
  ${animationStyles};
`;

