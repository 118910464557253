import React from 'react';
import { css } from '@linaria/core';
import ReactTooltip from 'react-tooltip';
import media from '../../utils/mediaQueries';

// Element tooltip should be triggered on must have data-tip data-for={tooltipId}

const Tooltip = props => (
  <ReactTooltip
    id={props.id} // tooltipId
    place="top"
    className={`${tooltipStyles} custom-tooltip`}
    effect="solid"
    backgroundColor='white'
    borderColor='#F3F4F4'
    arrowColor='white'
    textColor='var(--ucDarkBlue)'
    globalEventOff='click'
  >
    {props.text}
  </ReactTooltip>
);

export default Tooltip;

export const tooltipStyles = css`
  padding: 8px;
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  border: 2px solid #F3F4F4 !important;
  box-sizing: border-box;
  box-shadow: -2px 4px 4px rgba(57, 64, 83, 0.38) !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  z-index: 400;
  max-width: 400px;
  ${media.smMax} {
    max-width: 300px;
  }
`;
