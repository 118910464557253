import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';
// components
import Divider from '../../component-library/components/Divider';
import SecondaryBtn from '../../component-library/components/buttons/SecondaryBtn';
import PopularCategories from './PopularCategories';
import { Section } from './Section';
import { BoldLink } from './StyledComponents';

const BottomSection = props => {

  const handleMobileSearchInput = () => {
    props.toggleMobileSearchInput();
    props.toggleSidebarMenu();
  }

  return (
    <>
      <Section>
        <PopularCategories popularCategories={props.popularCategories} reducedMotion={props.reducedMotion} />
      </Section>
      <Divider /> 
      <Section>
        <BoldLink href='/lists' target='_blank'>Browse All Lists</BoldLink>
        <StyledSecondaryBtn href={props.requestOrigin + 'm/are-you-a-service-provider/?utm_source=nominatepartnerbutton'} title='Nominate a Provider'>Nominate a Provider</StyledSecondaryBtn>
        <BoldLink href={props.requestOrigin + 'providers'}>Who is UpCity?</BoldLink>
        <BoldLink href={props.requestOrigin + 'our-community/methodology/'} target='_blank'>Our Rating Methodology</BoldLink>
        <BoldLink href={props.requestOrigin + 'providers'} target='_blank'>Why You Should Trust our Providers</BoldLink>
      </Section>
      <Divider />
      <Section>
        <BoldLink href='https://insights.upcity.com' target='_blank'>Log in</BoldLink>
        <BoldLink onClick={ handleMobileSearchInput } >Search Upcity</BoldLink>
      </Section>
    </>
  );
}

export default BottomSection;

// partials

const StyledSecondaryBtn = styled(SecondaryBtn)`
  max-width: 100%;
  margin: 20px 0;
  font-size: 20px;
  background-color: white;
`;
