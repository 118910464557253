import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from '../icons/Search';
import { Label } from './partials';
import { css } from '@linaria/core';
import media from '../../utils/mediaQueries';
import Close from '../icons/Close2';
import { triggerClickOnEnterOrSpaceDown } from '../../../../utilities/ADA';
import { debounce } from '../../../../utilities/helpers';
import ListApi from "./../../../../api/ListApi.js";
import { SearchInputLiveResults } from './SearchInputLiveResults';

const applySearch = debounce((q, search_url, setTypeaheadValues) => {
  const page = 1;
  const params = { q: q, page: page, per_page: 4, typeahead: true};
  ListApi.getMoreProfilesOrSort(search_url, params)
    .then(({ data }) => {
        var results = data.data;
        setTypeaheadValues(results);
      }
    )
    .catch((error)=> console.log(error));
},300);

export const SearchInput = ({ toggleMobileSearchInput, search_url }) => {

  const ref = useRef(null);
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [typeaheadValues, setTypeaheadValues] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [activeOptionIndex, setActiveOptionIndex] = useState(null);

  useEffect(() => {
    if (inputValue.length > 0) {
      setShowClearBtn(true);
      setShowOptions(true);
    } else {
      setShowClearBtn(false);
      setShowOptions(false);
    }
  }, [inputValue])

  const handleInputFocus = () => {
    if (inputValue.length > 0) {
      applySearch(inputValue, search_url, setTypeaheadValues);
      setShowOptions(true);
      setActiveOptionIndex(null);
    }
  }

  const handleInputOnBlur = e => {
    if (e.target.value === '') {
      setShowClearBtn(false);
    } else {
      setTimeout(() => {
        setShowOptions(false);
      }, 400);
    }
  }

  const handleUserSearchInput = (e, search_url) => {
    setInputValue(e);
    setShowClearBtn(true);

    if (e.length >= 3) {
      applySearch(e, search_url, setTypeaheadValues);
      setShowOptions(true);
    } else {
      setShowOptions(false);
      setActiveOptionIndex(null);
    }
  };

  const handleClearBtnClick = () => {
    setInputValue('');
    setShowClearBtn(false);
    setActiveOptionIndex(null);
  }

  const handleFocus = () => {
    ref.current.focus();
    setShowOptions(true);
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 38) {
      if (activeOptionIndex === 0) {
        return;
      } else {
        setActiveOptionIndex(activeOptionIndex - 1);
      }
    } else if (e.keyCode === 40) {
      const currentOptionsLength = typeaheadValues.profile.data.length + typeaheadValues.list.data.length + typeaheadValues.wordpress_post.data.length;
      const activeOptionIndexNum = Number.isInteger(activeOptionIndex) ? activeOptionIndex : -1;

      if (activeOptionIndex === currentOptionsLength - 1) {
        return;
      }
      setActiveOptionIndex(Math.min(activeOptionIndexNum + 1, currentOptionsLength - 1));
    }
  }

  const onSubmit = e => {
  if (activeOptionIndex != null) {
    e.preventDefault();
    const object = [
      ...typeaheadValues.profile.data,
      ...typeaheadValues.list.data,
      ...typeaheadValues.wordpress_post.data
    ][activeOptionIndex];
    window.location.href = object.profile_url || object.url || object.path;
  }}

  return (
    <div onKeyDown={onKeyDown}>
      <form action={search_url} onSubmit={onSubmit} onClick={ toggleMobileSearchInput } tabIndex='0' onKeyPress={ triggerClickOnEnterOrSpaceDown } >
        <div className={SearchInputStyle} onClick={ handleFocus }>
          <SearchIcon title={'Search UpCity'} description={'Enter a business name, service category, or resource topic'} role='button' />
          <div>
            <input
              placeholder='Search UpCity'
              title='Search UpCity'
              type='search'
              name='q'
              id='search'
              value={inputValue}
              autoComplete='off'
              className={inputDataText}
              ref={ref}
              onChange={ e => handleUserSearchInput(e.target.value, search_url) }
              onFocus={handleInputFocus}
              onBlur={handleInputOnBlur}
            />
          </div>
          {
            showClearBtn &&
            <Close
              className={closeIcon}
              onClick={handleClearBtnClick}
            />
          }
        </div>
      </form>
      {
        typeaheadValues !== null && showOptions && inputValue.length >= 3 &&
        <SearchInputLiveResults typeaheadValues={typeaheadValues} search_url={search_url} inputValue={inputValue} onKeyDown={onKeyDown} activeOptionIndex={activeOptionIndex} />
      }
    </div>
  )
}

const SearchInputStyle = css`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  gap: 10px;
  height: 55px;
  margin-left: 20px;
  margin-top: 5px;
  padding: 0px 10px;
  position: relative;
  input {
    margin-top: 5px;
    outline: none!important;
    height: 55px;
    border: none;
    background-color: transparent;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 7px;
    color: var(--ucDarkBlue);
    ::placeholder {
      font-weight: 500;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 22px;
      color: var(--ucDarkBlue);
    }
  }

  // Remove clear button on all search inputs
  input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
  input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  img {
    width: 22px!important;
    height: 22px!important;
    min-width: 22px!important;
    margin-top: 16px!important;
    color: var(--ucDarkBlue)!important;
    cursor: pointer;
  }

  svg {
    right: 20px;
    top: 5px;
    position: absolute;
  }

  ${media.smMax} {
    width: 44px;
    height: 44px;
    margin-left: 0px;
    margin-top: 0px;
    justify-content: center;
    padding: 0px;
    gap: 0px;
    label {
      display: none!important;
    }
    input {
      display: none!important;
    }
    img {
      margin-top: 12px!important;
    }
    svg {
      display: none;
    }
    :hover {
      background: transparent;
    }
  }

  ${media.SearchInputExtraSmallDevices} {
    margin: 0px;
  }

  ${media.lgDevices} {
    input {
      font-size: 1rem;
    }
    ::placeholder {
      font-size: 1rem;
    }
   }
`;

const inputDataText = css`
  padding:13px; 
  border-width:1px; 
  border-radius:9px; 
  border-style:solid; 
  border-color:#d9d9d9; 
  width:100%;
  box-sizing: border-box;
  .inputwrapper::after {
    content: attr(data-required);
    position: absolute;
    right: 8px;
    top: 50%;
    font-size: 15px;
    transform: translateY(-50%);
    color: #ccc;
  }
`;

const closeIcon = css`
  width: 16px;
  height: 16px;
  margin-top: 20px;
  cursor: pointer;

  @keyframes wiggle {
    0% { transform: rotate(0deg); }
    80% { transform: rotate(10deg); }
    85% { transform: rotate(15deg); }
    95% { transform: rotate(5deg); }
    100% { transform: rotate(-10deg); }
  }

  :hover {
    animation: wiggle 0.5s infinite;
  }
`;
