import { css } from '@linaria/core';
import media from '../utils/mediaQueries';

export const hideOnSmScreen = css`${media.smMax} { display: none !important; }`;
export const hideOnMdScreen = css`${media.mdMax} { display: none !important; }`;
export const hideOnLrgScreen = css`${media.lrgMax} { display: none !important; }`;

export const displayInline = css`display: inline;`;
export const displayNone = css`display: none`;
export const displayFlex = css`display: flex`;
export const displayBlock = css`display: block`;