import React from 'react';
import { cx, css } from '@linaria/core';
import BaseBtn from './BaseBtn';

// takes alias that will render a tag if true
const PrimaryBtnBase = props => {
  const { colorCode } = props;
  const newProps = {...props};
  delete newProps.colorCode;
  
  let colorClass;
  
  switch (colorCode) {
    case 'purple':
      colorClass = purple;
      break;
    case 'orange':
      colorClass = orange;
      break;
    case 'darkBlue':
      colorClass = darkBlue;
      break;
  }
  return (
    <BaseBtn {...newProps} className={cx(shared, colorClass, props.className)} />
  );
}

export default PrimaryBtnBase;

const shared = css`
  color: #fff;
  text-decoration: none;
  &:hover, &:active {
    color: var(--ucDarkBlue);
  }
`;

const orange = css`
  border: 2px solid var(--ucOrange);
  background-color: var(--ucOrange);

  &:hover, &:active {
    border: 2px solid var(--ucOrange);
    background-color: rgba(255, 255, 255, 1);
  }
`;

const darkBlue = css`
  border: 2px solid var(--ucDarkBlue);
  background-color: var(--ucDarkBlue);

  &:hover, &:active {
    border: 2px solid var(--ucDarkBlue);
    background-color: transparent;
  }
`;

const purple = css`
  border: 2px solid var(--ucPurple);
  background-color: var(--ucPurple);

  &:hover, &:active {
    border: 2px solid var(--ucPurple);
    background-color: transparent;
  }
`;