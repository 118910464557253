import React from 'react';
import ReactSVG from 'react-inlinesvg';
import { styled } from '@linaria/react';
import icon from '../../../../../../images/local-marketing-agencies/icons/arrow-down-1.svg';

const ArrowDown = props => (
  <StyledSVG
    style={props.style}
    src={icon}
    className={props.className}
    onClick={props.onClick}
    width={props.width}
    color={props.color}
    title={props.title}
  />
);

export default ArrowDown;

const StyledSVG = styled(ReactSVG)`
  > * {
    fill: ${props => props.color || 'var(--ucDarkBlue)'};
  };
  min-width: ${props => props.width || ''};
`;