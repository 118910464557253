import React, { useEffect, useState } from 'react'
import { css } from '@linaria/core';
import { HoverBox } from './partials';
import { OptionsWrapper } from './selectComponents/partials';
import media from '../../utils/mediaQueries';
import { styled } from '@linaria/react';
import PrimaryBtnBase from '../buttons/PrimaryBtnBase';

export const SearchInputLiveResults = ({
  typeaheadValues,
  search_url,
  inputValue,
  activeOptionIndex,
  onKeyDown,
}) => {

  const [showOptions] = useState(true);
  const [profile, setProfile] = useState(typeaheadValues.profile.data);
  const [list, setlist] = useState(typeaheadValues.list.data);
  const [wordpress_post, setWodpress_post] = useState(typeaheadValues.wordpress_post.data);

  useEffect(() => {
    if (inputValue.length > 0) {
      setProfile(typeaheadValues.profile.data);
      setlist(typeaheadValues.list.data);
      setWodpress_post(typeaheadValues.wordpress_post.data);
    }
  } , [inputValue, typeaheadValues]);

  return (
    <OptionsWrapper
      showOptions={showOptions}
      className={optionswrapper}
    >
      <div className={box_label}>
        <label className={label}>
          Choose a top result or press "enter" to expand your search
        </label>
      </div>
      <div className={title_container}>
        <a data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='Results Page' href={`${search_url}?q=${inputValue}&group_by=profile`}><span className={search_result_title}>TOP MATCHING COMPANY NAMES</span></a>
      </div>
      {
        profile.map((profile, index) => (
          <HoverBox
            className={hoverbox}
            onKeyDown={ onKeyDown }
            key={index.toString()}
            data-label={profile.name}
            index={index}
            value={profile.name}
            active={profile.name}
            focus={index === activeOptionIndex}
          >
            <div className={hoverbox_title}>
              <a
                tabIndex="-1"
                data-gtm-event-click-id='search-typeahead-click'
                data-gtm-event-custom-action='Profile Selection'
                href={profile.profile_url}
                className={hoverbox_title_link}
              >
                {profile.business_name}
              </a>
            </div>
          </HoverBox>
        ))
      }
      <div className={title_container}>
        <a data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='Results Page' href={`${search_url}?q=${inputValue}&group_by=list`}> <span className={search_result_title}>TOP MATCHING LISTS / SERVICE CATEGORIES</span></a>
      </div>
      {
        list.map((list, index) => (
          <HoverBox 
            className={hoverbox} 
            onKeyDown={ onKeyDown }
            key={index.toString()}
            data-label={list.name}
            index={index}
            value={list.name}
            active={list.name}
            focus={(index + profile.length) === activeOptionIndex}
          >
            <div className={hoverbox_title}>
              <a tabIndex="-1" data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='List Selection' href={list.url} className={hoverbox_title_link}>
                {list.page_title}
              </a>
            </div>
          </HoverBox>
        ))
      }
      <div className={title_container}>
        <a data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='Results Page' href={`${search_url}?q=${inputValue}&group_by=wordpress_post`}> <span className={search_result_title}>TOP MATCHING ARTICLES / TOOLS</span></a>
      </div>
      {
        wordpress_post.map((wordpress_post, index) => (
          <HoverBox 
            className={hoverbox}
            onKeyDown={ onKeyDown }
            key={index.toString()}
            data-label={wordpress_post.name}
            index={index}
            value={wordpress_post.name}
            active={wordpress_post.name}
            focus={(index + profile.length + list.length) === activeOptionIndex}
          >
            <div className={hoverbox_title}>
              <a tabIndex="-1" data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='Tools Selection' href={wordpress_post.path} className={hoverbox_title_link}>
                {wordpress_post.post_title}
              </a>
            </div>
          </HoverBox>
        ))
      }
      <div className={footer_box}>
        <StyledPrimaryBtnBase colorCode='darkBlue' className={getStartedBtnStyles} href={`${search_url}?q=${inputValue}`} title='View All Results' tabIndex="-1">View All Results</StyledPrimaryBtnBase>
      </div>
    </OptionsWrapper>
  )
}

const box_label = css`
  background: white;
  height: 40px;
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const label = css`
  color: rgba(57, 64, 83, 0.75);
  display: block;
  font-family: 'verbregular';
  font-size: 0.7em;
  line-height: 150%;
  font-style: italic;
  font-weight: 600;
  margin: 8px auto 5px auto;
  text-align: center;
`;

const optionswrapper = css`
  height: fit-content;
  left: 154px;
  max-height: calc(100vh - 100px);
  max-width: 400px;
  min-width: 400px;
  top: 75px;
  transition: all 0.2s ease-in-out;
`;

const hoverbox = css`
  display: flex!important;
  align-items: center!important;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  font-size: 16px;
  justify-content: space-between;
  width: 100%;
  min-height: 45px;

  .nav-item {
    padding: 10px 0 0 0;
  }
`;

const hoverbox_title = css`
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%!important;
    width: 100%;
    padding: 0 0 0 20px!important;
  }
`;

const hoverbox_title_link = css`
  color: var(--ucDarkBlue)!important;
  font-weight: normal!important;
  text-decoration: none;
`;

const title_container = css`
  text-decoration: none;
  background: rgba(57,64,83,0.2);
  color: rgba(57,64,83,1);
  font-size: 0.8em;
  line-height: 150%;
  padding: 5px;
  text-transform: uppercase;
  a {
    max-width: 100% !important;
    display: block;
    text-decoration: none;
  }
`;

const search_result_title = css`
  width: 100%;
  color: rgba(57,64,83,1);
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
`;

const footer_box = css`
  align-content: center;
  align-items: center;
  bottom: 0;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 5px;
  position: sticky;
  width: 400px;
  background: white;
`;

const StyledPrimaryBtnBase = styled(PrimaryBtnBase)`
  ${media.smMax} { width: auto; }
`;

const getStartedBtnStyles = css`
  font-size: 12.8px!important;
  padding: 10px 16px;
  z-index: 1;

  ${media.smMax} {
    margin: 0 10px;
    padding: 10px;
  }
`;
