import React from 'react';
import { styled } from '@linaria/react';
import Icon from '../component-library/components/icons/Hamburger';
// utils
import media from '../component-library/utils/mediaQueries';
import { triggerClickOnEnterOrSpaceDown } from '../../utilities/ADA';

const Hamburger = ({ className, onClick }) => {
  return (
    <Wrapper className={`${className} tactile-animation`} onClick={onClick} tabIndex='0' onKeyPress={triggerClickOnEnterOrSpaceDown} >
      <Icon width='28px' title='Toggle sidebar menu' />
    </Wrapper>
  );
}

export default Hamburger;

//partials

const Wrapper = styled.div`
  padding: 0 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  display: none;
  ${media.mdMax} {
    display: flex;
  }
`;

