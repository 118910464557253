import { styled } from '@linaria/react';
import LinkBase from '../../component-library/components/links/Link';

export const Link = styled(LinkBase)`
  display: block;
  padding-bottom: 12px;
  margin: ${props => props.margin || ''};
  :not(.tel) {
    padding-top: 12px;
  }
`;

export const BoldLink = styled(Link)`
  font-weight: 700;
`;
