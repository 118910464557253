import Link2 from '../Link2';
import { styled } from '@linaria/react';

const Link3 = styled(Link2)`
  color: var(--ucDarkBlue);
  .text {
    font-weight: ${props => props.fontWeight || '400'};
  }
`;

export default Link3;
