import React from 'react';
import { styled } from '@linaria/react';
import { cx, css } from '@linaria/core';
import media from '../../../utils/mediaQueries';
import ArrowDown from '../../icons/ArrowDown';

export const SelectWrapper = styled.div`
  position: relative;
  width: ${props => props.width || 'auto'};
  pointer-events: ${props => props.disabled ? 'none' : ''};
  opacity: ${props => props.disabled ? '0.6' : ''};

  .input {
    padding: 10px 0;
    position: relative;
    cursor: pointer;
    font-size: 16px;
  }

  input {
    padding-left: 10px;
    width: 100%;
    height: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
  }

  :focus-within {
    input {
      box-shadow: 0px 0px 10px #287495, 0px 1px 3px #287495 !important;
      // outline: 2px solid var(--ucBlue) !important;
    }
  }

  .input-name {
    will-change: transition, top;
    font-style: italic;
    line-height: 14px;
    position: absolute;
    top: 17px;
    transition: top 0.2s linear;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
    white-space: nowrap;
  }

  ${media.smMax} {
    width: 100%;
  }
`;

const rotate = css`
  background-color: var(--ucBlue20);
  svg { transform: rotate(180deg); }
`;

const ToggleWrapper = props => {
  const styles = props.showOptions ? rotate : null;
  return (
    <div className={cx(props.className, styles)}>
      <ArrowDown width='15px' color='var(--ucBlue)' title='Toggle options' />
    </div>
  )
}

export const Toggle = styled(ToggleWrapper)`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 6px;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
`;

export const OptionsWrapper = styled.div`
  display:inline-block;
  position: absolute;
  left: 0;
  min-width: 100%;
  z-index: 1000;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 100%;
  width: 100%;
  border: ${props => props.showOptions ? '2px solid var(--ucBlue)' : ''};
  box-shadow: 0px 8px 20px var(--ucDarkBlue20);

  /* Initially we want menu to be hidden */
  max-height: 0;
  overflow: hidden;
  overflow-y: scroll !important;

  /* Set our transitions up. */
  -webkit-transition: max-height 0.4s;
  -moz-transition: max-height 0.4s;
  transition: max-height 0.4s;
  max-height: ${props => props.showOptions ? '430px' : '0px'};

  @media screen and (max-width: 750px) {
    transition: none;
  }
`;
