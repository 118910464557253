import React, { useState } from 'react'
import { styled } from '@linaria/react';
import { Label } from '../../component-library/components/formComponents/partials';
import { css } from '@linaria/core';
import loader from '../../../images/spinner200px.gif';
import { triggerClickOnEnterOrSpaceDown } from '../../../utilities/ADA';
import { debounce } from '../../../utilities/helpers';
import { HoverBox } from '../../component-library/components/formComponents/partials';
import ListApi from "./../../../api/ListApi.js";

const applySearch = debounce((q, search_url, setTypeaheadValues) => {
  const page = 1;
  const params = { q: q, page: page, per_page: 4, typeahead: true};
  ListApi.getMoreProfilesOrSort(search_url, params)
    .then(({ data }) => {
        var results = data.data;
        if (data.meta.total > 0) {
          setTypeaheadValues(results);
        } else {
          setTypeaheadValues(null);
        }
      }
    )
    .catch((error)=> console.log(error));
},300);

export const SearchInputMobile = ({ toggleMobileSearchInput, search_url }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [typeaheadValues, setTypeaheadValues] = useState(null);

      React.useEffect(() => {
        if (isLoading) {
          const searchTerm = inputValue;
           // TODO do not hardcode urls, pass in search_url
           const search_url = `${window.location.origin}/search?q=${searchTerm}`;
           window.location.href = search_url;
        }
       }, [isLoading]);

  const handleUserSearchInputMobile = (e, search_url) => {
    setInputValue(e.target.value);
    if (e.target.value.length >= 3) {
      applySearch(e.target.value, search_url, setTypeaheadValues);
    } else {
      setTypeaheadValues(null);
    }
  };

  const handleClearBtnClick = () => {
    toggleMobileSearchInput();
  }

  const handleSearch = () => {
    setIsLoading(true);
  }

  return (
    <>
      <div className={SearchInputMobileStyle}>
        <div className={textInputContainer}>
          <Label>Search UpCity</Label>
          <form action='.' onSubmit={ handleSearch } >
            <input
              autoFocus
              placeholder='Find companies, services, etc'
              type='search'
              name='q'
              id='search'
              value={inputValue}
              autoComplete='off'
              className={inputDataText}
              onChange={ (e) => handleUserSearchInputMobile(e, search_url)}
              description='Enter a business name, service category, or resource topic'
            />
          </form>
        </div>
        <div className={CancelBtnStyle} onClick={handleClearBtnClick} tabIndex='0' onKeyPress={ triggerClickOnEnterOrSpaceDown } >
          {
            isLoading ? <img
                          src={ loader }
                          alt='spinner'
                          className={spinner}
                        />
                      : <h5>Cancel</h5>
          }
        </div>
      </div>
      { typeaheadValues &&  <TypeaheadResults results={typeaheadValues} q={inputValue} search_url={search_url}/>}
    </>
  )
}

const ModelResultsHeaderText = modelType => {
  var text;
  switch (modelType) {
    case 'profile':
      text = 'TOP MATCHING COMPANY NAMES';
      break;
    case 'list':
      text = 'TOP MATCHING LISTS/SERVICES CATEGORIES';
      break;
    case 'wordpress_post':
      text = 'TOP MATCHING ARTICLES/TOOLS';
      break;
    default:
      text = "No value found";
  }
  return text;
}

const ModelResults = props => {
  return (
    <div>
      <a data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='Results Page' className='title' href={`${props.search_url}?q=${props.q}&group_by=${props.modelType}`}>{ModelResultsHeaderText(props.modelType, props.searchTerm)}</a>
        {props.results.data.map(result =>  (
          <React.Fragment key={result.id}>
            { props.modelType === 'profile' &&
              <a href={result.profile_url} className={css`text-decoration:none`} data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='Profile Selection'>
                <HoverBox minHeight='40px'>{result.business_name}</HoverBox>
              </a>
            }
            { props.modelType === 'list' &&
              <a href={result.url} className={css`text-decoration:none`} data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='List Selection'>
                <HoverBox minHeight='40px'>{result.page_title}</HoverBox>
              </a>
            }
            { props.modelType === 'wordpress_post' &&
              <a href={result.path} className={css`text-decoration:none`} data-gtm-event-click-id='search-typeahead-click' data-gtm-event-custom-action='Tools Selection'>
                <HoverBox minHeight='40px'>{result.post_title}</HoverBox>
              </a>
            }
          </React.Fragment>
        ))
      }
    </div>
  );
};

const TypeaheadResults = (props) => {
  return(
    <div className={TypeaheadSelectOptions}>
      <TypeaheadText>Choose a top result or press “enter” to expand your search</TypeaheadText>
      <div className={TypeaheadGridStyle}>
      { Object.keys(props.results).map(key => (
        props.results[key].meta.count > 0 &&
          <ModelResults modelType={key} search_url={props.search_url} q={props.q} results={props.results[key]}/>
      ))}
      <div className={css`min-height: calc(50vh)`}></div>
      </div>
    </div>
  );
}


const TypeaheadText = styled.div`
  display: block;
  font-family: 'verbregular';
  color: rgba(57, 64, 83, 0.75);
  font-style: italic;
  font-size: 0.8rem;
  line-height: 150%;
  font-weight: 600;
  text-align: center;
  padding: 5px;
`;


const TypeaheadSelectOptions = css`
  display: flex;
  flex-flow: column;
  height: calc(100vh - 60px);
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 1002;
  background-color: white;
  width: 100%;
`;

const TypeaheadGridStyle = css`
  flex-grow : 1;
  overflow: scroll;
  a.title {
    background-color: lightgray;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    display: block;
    color: black;
    text-decoration: none;
    padding-left: 20px;
  }
  > * {
    border-top: 1px solid #dfdfdf;
  }
  .nav-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SearchInputMobileStyle = css`
  width: 80%!important;
  background-color: white;
  position: fixed;
  z-index: 1002;
  left: 0;
  top: 0;
  padding: 0 20px;
  border-top: 3px solid var(--ucDarkBlue);
  border-bottom: 3px solid var(--ucDarkBlue);
  box-shadow: 0px 0px 10px #287495;
  input {
    padding: 7px 0;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    color: var(--ucDarkBlue);
    ::placeholder {
      font-weight: 500;
      font-size: 1rem;
      font-weight: 400;
      line-height: 22px;
      color: var(--ucDarkBlue);
    }
  }
  input#search:focus {
    border: none;
    box-shadow: none;
  }

  // Remove clear button on all search inputs
  input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
  input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }
  
  :hover {
    input {
      color: var(--ucDarkBlue)!important;
    }
  }
`;

const inputDataText = css`
  padding:13px; 
  border-width:1px;
  border-style:solid; 
  border-color:#d9d9d9; 
  width:100%;
  box-sizing: border-box;
  .inputwrapper::after {
    content: attr(data-required);
    position: absolute;
    right: 8px;
    top: 50%;
    font-size: 15px;
    transform: translateY(-50%);
    color: #ccc;
  }
`;

const textInputContainer = css`
  label {
    font-size: 14px;
    font-weight: 600;
    font-family: 'verbregular';
    line-height: 17.33px;
    color: #394053BF;
  }
`;

const CancelBtnStyle = css`
  width: 20%!important;
  height: 55px;
  background-color: white;
  right: 0;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 0px 12px -2px #000000;
  cursor: pointer;

`;

const spinner = css`
  width: 50px;
`;
