import React from 'react';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import media from '../../utils/mediaQueries';

export const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 800;
  color: var(--ucDarkBlue);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

export const ErrorMsg = styled.small`
  display: block;
  position: absolute;
  line-height: 1.65;
  color: var(--ucRed);
  font-weight: 600;
`;

export const HoverBox = props => {
  return (
    <HoverBoxWrapper
      className={`${props.className} ${props.active || props.focus ? activeStyles : ''}`}
      onClick={props.onClick}
      onMouseDown={props.onKeyDown}
      onKeyDown={props.onKeyDown}
      index={props.index}
      value={props.value}
      focus={props.focus}
      disabled={props.disabled}
      minHeight={props.minHeight}
    >
      <div className='left-border-box' />
      <div className='nav-item'>{props.children}</div>
    </HoverBoxWrapper>
  );
}

const HoverBoxWrapper = styled.div`
  position: relative;
  font-family: 'verbregular';
  font-weight: 400;
  text-decoration: none;
  display: flex;
  align-items: stretch;
  min-height: ${props => props.minHeight ? props.minHeight : '60px'};
  border-bottom: 1px solid var(--ucLightGray);
  background-color: ${props => props.focus ? 'var(--ucGray20)' : 'white'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'} !important;

  &:active {
    transform: translateY(2px);
  }


  .left-border-box {
    display: inline-block;
    position: absolute;
    left: 0;
    height: 100%;
    background-color: ${props => !props.disabled ? 'white' : ''};
  }

  .nav-item {
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    font-size: 16px;
    padding: 16px 20px;
    color: ${props => props.disabled ? 'var(--ucDarkBlue57)' : 'var(--ucDarkBlue)'};

    a { font-weight: 600; }
  }

  &:hover:not(.disabled) {
    background-color: var(--ucBlue20);
    width: 100%;
    font-family: 'verbregular';
    font-weight: 700;

    .nav-item a, .nav-item {
      text-decoration: none;
    }

    .left-border-box {
      background-color: var(--ucBlue20);
      border-left: 6px solid var(--ucBlue);
      transition: border-left 0.2s linear;
    }
  }
`;

const activeStyles = css`
  width: 100%;

  .nav-item a, .nav-item {
    font-weight: 700;
    text-decoration: none;
  }

  .left-border-box {
    background-color: var(--ucBlue20);
    border-left: 6px solid var(--ucBlue);
  }
`;

// shared styles between SelectTypeahead, Select and TextInput

const SharedInputWrapper = styled.div`
  position: relative;
  width: ${props => props.width || '100%'};
  svg { height: auto; }

  ${media.smMax} { width: 100%; }

  input, textarea {
    min-height: 55px;
    font-family: 'verbregular';
    width: 100%;
    font-size: 1em;
    line-height: 1.65;
    color: var(--ucDarkBlue75);
    display: inline-block;
    background-color: var(--ucGhosted);
    border: 1px solid var(--ucDarkBlue57);
    box-sizing: border-box;
    border-radius: 10px;
    text-indent: ${props => props.icon ? '30px' : '0' };
    -webkit-appearance: none;
    ::placeholder {
      color: var(--ucDarkBlue75);
    }
  }

  :hover {
    input, textarea {
      border: 2px solid var(--ucBlue);
      background-color: white;
    }
  }

  label {
    position: absolute;
    z-index: 1;
    top: 7px;
    right: 0;
    left: ${props => props.icon ? '40px' : '12px' };
  }

  .error:not(:focus) {
    border: 2px solid var(--ucRed);
  }

  .icon {
    display: inline;
    position: absolute;
    right: ${props => props.icon ? '0px' : '-9px' };
    left: ${props => props.icon ? '10px' : '0px' };
    top: ${props => props.icon ?  '20px' : '0px' };
  }

  .status-icon {
    display: inline;
    position: absolute;
    left: 0px;
    top: 20px;
    right: -9px;
    margin-left: auto;
  }

  :focus-within, :focus, :focus-visible {
    .status-icon {
      display: none;
    }

    input, textarea {
      outline: none !important;
      box-shadow: 0px 0px 10px #287495, 0px 1px 3px #287495;
      -moz-box-shadow: 0px 0px 10px #287495, 0px 1px 3px #287495;
      -webkit-box-shadow: 0px 0px 10px #287495, 0px 1px 3px #287495;
      border: 2px solid var(--ucBlue);
    }
  }

  input:focus, textarea:focus {
    outline:none;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: var(--ucBlue) !important;
    box-shadow: 0px 0px 10px #287495, 0px 1px 3px #287495;
    -moz-box-shadow: 0px 0px 10px #287495, 0px 1px 3px #287495;
    -webkit-box-shadow: 0px 0px 10px #287495, 0px 1px 3px #287495;
    border: 2px solid var(--ucBlue);
  }
`;

export const TextInputWrapper = styled(SharedInputWrapper)`
  input, textarea {
    padding: 26px 10px 2px 10px;
    box-shadow: inset 0px 2px 4px 0px var(--ucDarkBlue57);
  }

  :hover, :focus-within, :focus, :focus-visible {
    input, textarea {
      padding: 25px 9px 1px 9px;
    }
  }

  .error:not(:focus), input:focus, textarea:focus { {
    padding: 25px 9px 1px 9px;
  }
`;


export const SelectTypeaheadWrapper = styled(SharedInputWrapper)`
  input, textarea {
    padding: 26px 10px 2px 10px;
    box-shadow: -1px 2px 4px rgba(57, 64, 83, 0.38);
  }

  :hover {
    input, textarea {
      padding: 25px 9px 1px 9px;
    }
  }

  .error:not(:focus) {
    padding: 25px 9px 1px 9px;
  }


  :focus-within, :focus, :focus-visible {
    input, textarea {
      padding: 25px 9px 1px 9px;
    }
  }

  input:focus, textarea:focus {
    padding: 25px 9px 1px 9px;
  }
`;

export const SelectInputWrapper = styled(SharedInputWrapper)`
  input, textarea {
    padding: 2px 2px 2px 15px;
    box-shadow: -1px 2px 4px rgba(57, 64, 83, 0.38);
  }

  :hover {
    input, textarea {
      padding: 0 0 0 15px;
      cursor: pointer;
    }
  }

  .error:not(:focus) {
    padding: 0 0 0 15px;
  }


  :focus-within, :focus, :focus-visible {
    input, textarea {
      padding: 0 0 0 15px;
    }
  }

  input:focus, textarea:focus {
    padding: 0 0 0 15px;
  }
`;

