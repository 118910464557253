import React from 'react';
import { css } from '@linaria/core';
import PropTypes from 'prop-types';
import media from '../../component-library/utils/mediaQueries';

const List = props => (
  <div
    className={css`
      ul {
        padding-inline-start: 0px;
        list-style-type: none;
      }
      padding: 30px 30px 0 30px;
      width: 20%;
      ${media.mdMax} { width: 33%; }
      ${media.smMax} { width: 100%; }
    `}>
    {props.lists.map((list, i) => (
      <React.Fragment key={i}>
        <h4>{list.title}</h4>
        <ul>
          {list.items.map((item, i) => {
            return (
              <li key={i}>
                <a
                  className={css`
                    color: var(--ucYellow);
                    display: inline-block;
                    font-weight: 400;
                    line-height: 35px;
                    font-size: 18.72px;
                    padding: 7.48px;
                    text-decoration: none;
                    :hover {
                      text-decoration: underline;
                      color: rgba(237,181,86,.57);
                    }
                  `}
                  target='_blank'
                  href={item.href}
                >
                  {item.value}
                </a>
              </li>
            )
          })}
        </ul>
      </React.Fragment>
    ))}
  </div>
);

List.propTypes = {
  title: PropTypes.string,
  lists: PropTypes.array.isRequired
};

export default List;
