import React from 'react';
import ReactSVG from 'react-inlinesvg';
import { styled } from '@linaria/react';
import Facebook from '../../../../../images/local-marketing-agencies/icons/social-media-facebook.svg';
import LinkedIn from '../../../../../images/local-marketing-agencies/icons/professional-network-linkedin.svg';
import X from '../../../../../images/local-marketing-agencies/icons/social-media-x.svg';

const SocialMediaIcon = props => {
  const socialComponents = {
    'facebook': Facebook,
    'x': X,
    'linked_in': LinkedIn,
  };

  return (
    <a target='_blank' {...props} >
      <ReactSVG src={socialComponents[ props.icon ]} width='28px' />
    </a>
  );
};

export default styled(SocialMediaIcon)`
  svg {
    padding: 8px;
    margin-right: 22px;
    background-color: gray;

    path {
      fill: var(--ucIcon);
    }

    rect {
      fill: var(--ucIcon);
    }

    ellipse {
      fill: var(--ucIcon);
    }

    &:hover {
      background-color: var(--ucLightBlue);

      path, circle, ellipse, rect {
        fill: white;
      }

      circle {
        stroke: white;
      }
    }
  }
`;
