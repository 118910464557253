import React from 'react';
import { styled } from '@linaria/react';

const DataBox = props => (
  <Wrapper className={props.className} id={props.id} style={props.style}>
    {props.children}
  </Wrapper>
);

export default DataBox;

const Wrapper = styled.div`
  padding: 1em;
  background-color: white;
  border-radius: 20px;
  border: 1px solid var(--ucDarkBlue20);
  @media not print {
    box-shadow: 0 8px 20px var(--ucDarkBlue20);
    -moz-box-shadow: 0 8px 20px var(--ucDarkBlue20);
    -webkit-box-shadow: 0 8px 20px var(--ucDarkBlue20);
  }
  @media print {
    border: 1px solid var(--ucDarkBlue20);
  }
`;
