import { styled } from '@linaria/react';

const Link = styled.a`
  line-height: 1.65;
  color: var(--ucDarkBlue);
  text-decoration: none;
  display: ${props => props.display || ''};
  margin: ${props => props.margin || ''};
`;

export default Link;

export const BoldLink = styled(Link)`
  font-weight: 700;
`;
