import { styled } from '@linaria/react';
import Link4 from './index';

const Link4Inline = styled(Link4)`
  display: inline;
  .text {
    display: inline;
    line-height: 1.65;
  }
`;

export default Link4Inline;
