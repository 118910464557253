import { css } from '@linaria/core';

export default css`
  will-change: max-height, top;
  position: relative;
  width: 100%;
  top: -300px;
  z-index: -1;
  max-height: 0px;
  visibility: hidden;
  padding: 0 3px;

  &.expanded-enter {
    overflow: hidden;
    max-height: 300px;
    z-index: 0;
    visibility: visible;
    transition: max-height 500ms ease-in;
  }

  &.expanded-enter-active {
    transition: top 500ms ease-in;
    top: 0px;
    transition: max-height 500ms ease-in;
    z-index: 0;
    max-height: 1000px;
    visibility: visible;
  }

  &.expanded-enter-done {
    top: 0px;
    z-index: 0;
    transition: max-height 500ms ease-in;
    max-height: 1000px;
    visibility: visible;
  }

  &.expanded-exit {
    top: 0px;
    z-index: 0;
    max-height: 1000px;
    visibility: visible;
  }

  &.expanded-exit-active {
    transition: top 500ms ease-in;
    top: -300px;
    visibility: visible;
  }

  &.expanded-done {
    z-index: -1;
    top: -300px;
    transition: max-height 500ms ease-in;
    max-height: 0px;
    visibility: hidden;
  }

  // disables animation if user has set reduce motion to true on their machine
  &.reduced-motion {
    transition: none;
  }
`;
