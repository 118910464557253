import { styled } from '@linaria/react';
import BaseBtn from './BaseBtn';

const SecondaryBtn = styled(BaseBtn)`
  color: var(--ucDarkBlue);
  border: 2px solid var(--ucDarkBlue);
  background-color: ${props => props.backgroundColor || 'transparent'};

  &:hover, &:active {
    background-color: var(--ucDarkBlue) !important;
    color: #fff;
  }
`;

export default SecondaryBtn;
