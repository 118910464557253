import React from 'react';
import { styled } from '@linaria/react';
import media from '../../utils/mediaQueries';

const BaseBtn = props => {
  const Wrapper = props.as || props.href ? 'a' : 'button';
  const newProps = {...props};
  delete newProps.colorCode;
  return (
    <Wrapper {...newProps} />
  );
}

export default styled(BaseBtn)`
  width: ${props => props.width || ''};
  font-size: ${props => props.fontSize || '16px' };
  line-height: 130%;
  cursor: pointer;
  border-radius: 15px;
  padding: 13px 20px;
  font-weight: 600;
  box-sizing: border-box;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.38);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;

  ${media.mdMax} { font-size: 16px; }
  ${media.smMin} { max-width: 220px; }
`;

