import { styled } from '@linaria/react';
import Link2 from '../Link2';

const Link4 = styled(Link2)`
  color: var(--ucDarkBlue);
  .text {
    border-color: var(--ucOrange);
    font-weight: 400;
  }
  &:hover {
    .text {
      color: var(--ucBlue);
      border-color: var(--ucBlue);
    }
  }
`;

export default Link4;

