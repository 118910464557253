import React from 'react';
import ReactSVG from 'react-inlinesvg';
import { styled } from '@linaria/react';
import { cx, css } from '@linaria/core';
import icon from '../../../../../../images/local-marketing-agencies/icons/close-2.svg';

const Close = props => {
  const animateClass = props.animate ? animateCSS : null;
  const className = cx(props.className, animateClass);
  return (
    <Wrapper
      src={icon}
      className={className}
      onClick={props.onClick}
      width={props.width}
      title={props.title}
      height={props.height}
    />
  );
};

const Wrapper = styled(ReactSVG)`
  width: ${props => props.width || '60px'};
  height: ${props => props.height || '60px'};
  
  svg {
    line {
      stroke-width: 1px;
      stroke: var(--ucGhosted);
    }

    &:hover {
      cursor: pointer;

      line {
        stroke: var(--ucOrange);
      }
    }
  }
`;

export default Close;

const animateCSS = css`
  @keyframes wobble {
    0% { transform: rotate(0deg); transform-origin: center;}
    25% { transform: rotate(20deg); transform-origin: center;}
    75% { transform: rotate(-20deg); transform-origin: center;}
    100% { transform: rotate(0deg); transform-origin: center;}
  }

  :hover {
    animation: wobble 1s linear 3;
    backface-visibility: hidden;
    perspective: 24px;
  }
`;
