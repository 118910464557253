import { styled } from '@linaria/react';
import bg from '../../../../../../images/local-marketing-agencies/backgrounds/bg-menu-panel.svg';

export default styled.div`
  .takeover {
    height: 0vh;
    width: 0px;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1001;
    background-color: rgba(255,255,255,0.5);
  }

  .inner {
    box-shadow: 0px 8px 20px var(--ucDarkBlue20);
    overflow: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    will-change: max-width, left, opacity;
    right: -400px;
    z-index: -1;
    max-width: 0px;
    visibility: hidden;
    padding: 10px;
    background-image: ${props => `url(${props.backgroundImage || bg})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    .close {
      display: flex;
      justify-content: flex-end;
      margin: 10px 10px 10px 5px;
      svg {
        padding: 8px;
        width: 34px;
        height: 34px;
        cursor: pointer;
      }
    }
  }

  &.show-sidebar-enter {
    .takeover {
      width: 100vw;
      height: 100vh;
    }
    .inner {
      overflow: hidden;
      right: -400px;
      max-width: 100%;
      min-width: 350px;
      z-index: 0;
      visibility: visible;
      transition: right 300ms ease-in;

      @media screen and (max-width: 350px) {
        min-width: 100%;
      }
    }
  }

  &.show-sidebar-enter-active {
    .takeover {
      width: 100vw;
      height: 100vh;
    }
    .inner {
      right: -400px;
      z-index: 3;
      max-width: 100%;
      min-width: 350px;
      visibility: visible;
      transition: right 300ms ease-in, opacity 300ms linear;

      @media screen and (max-width: 350px) {
        min-width: 100%;
      }
    }
  }

  &.show-sidebar-enter-done {
    .takeover {
      width: 100vw;
      height: 100vh;
    }
    .inner {
      right: 0px;
      z-index: 3;
      max-width: 100%;
      min-width: 350px;
      visibility: visible;
      transition: right 300ms ease-in, opacity 300ms linear;

      @media screen and (max-width: 350px) {
        min-width: 100%;
      }
    }
  }

  &.show-sidebar-exit {
    .takeover {
      width: 100vw;
      height: 100vh;
    }
    .inner {
      right: 0px;
      z-index: 3;
      max-width: 100%;
      min-width: 350px;
      visibility: visible;

      @media screen and (max-width: 350px) {
        min-width: 100%;
      }
    }
  }

  &.show-sidebar-exit-active {
    .takeover {
      width: 100vw;
      height: 100vh;
    }
    .inner {
      right: -400px;
      visibility: visible;
      transition: right 300ms ease-in, opacity 300ms linear;
    }
  }

  &.show-sidebar-done {
    .takeover {
      width: 0vw;
      height: 0vh;
    }
    .inner {
      z-index: -1;
      right: -400px;
      max-width: 0px;
      visibility: hidden;
      transition: right 300ms ease-in, opacity 300ms linear;
    }
  }


  // disables animation if user has set reduce motion to true on their machine
  &.reduced-motion {
    .inner {
      transition: none;
    }
  }
`;
