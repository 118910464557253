import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import ArrowDown from '../../../component-library/components/icons/ArrowDown';
import Link4Inline from '../../../component-library/components/links/Link4/Link4Inline';
import DataBox from '../../../component-library/components/DataBox';
import animationStyles from './animationStyles';
import { triggerClickOnEnterOrSpaceDown } from '../../../../utilities/ADA';

class PopularCategories extends React.Component {
  state = {
    showMenu: false,
    expandCategory: null
  }

  getInitialExpanedState = () => {
    return Object.keys(this.props.popularCategories).reduce((coll, next, i) => {
      coll[`itemExpanded${i+1}`] = false;
      return coll;
    }, {});
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMouseClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseClick, false);
  }

  handleMouseClick = e => {
    if (this.state.showMenu) {
      const root = document.getElementById('popular-cats-dropdown');
      const link = document.getElementById('expand-popular-categories-link');
      if (root.contains(e.target) || link.contains(e.target)) {
        return null;
      }
      this.handleClickOutside();
    }
  }

  handleClickOutside = () => this.setState({ showMenu: false, ...this.getInitialExpanedState() });

  expandCategory = stateKey => {
    if (stateKey === this.state.expandCategory) {
      this.setState({ expandCategory: null });
    } else {
      this.setState({ expandCategory: stateKey });
    }
  }

  render() {
    const reducedMotionClass = this.props.reducedMotion ? 'reduced-motion' : null;
    const categoryKeys = Object.keys(this.props.popularCategories || {});

    return (
      <div className={css`position: relative;`} >
        <StyledLink
          id='expand-popular-categories-link'
          className={`${this.props.className} tactile-animation`}
          linkColor={this.props.linkColor}
          onClick={() => this.setState({ showMenu: !this.state.showMenu, ...this.getInitialExpanedState() })}
          tabIndex='0' onKeyPress={ triggerClickOnEnterOrSpaceDown }
        >
          Other Popular Categories
          <ExpandIcon
            expanded={this.state.showMenu}
            color={this.props.linkColor || 'var(--ucGhosted)'}
            width='15px'
            title='Expand list of categories'
          />
        </StyledLink>
        <DropDown id='popular-cats-dropdown' display={this.state.showMenu ? 'block' :'none'} tabIndex='0' onKeyPress={ triggerClickOnEnterOrSpaceDown }>
          {categoryKeys.map((key, i) => {
            const stateKey = `itemExpanded${i+1}`;
            const isExpanded = this.state.expandCategory === stateKey;

            return (
              <CategoryWrapper key={key}>
                <div
                  className={css`
                    padding: 3px;
                    background-color: var(--ucGhosted);
                    position: relative;
                    z-index: 3;}
                  `}
                >
                  <Label
                    className='tactile-animation'
                    tabIndex='1'
                    onClick={this.expandCategory.bind(null, stateKey)}
                    onKeyPress={triggerClickOnEnterOrSpaceDown}
                  >
                    {key.toUpperCase()}
                    <ExpandIcon
                      className={stateKey}
                      expanded={isExpanded}
                      reducedMotion={this.props.reducedMotion}
                      width='15px'
                      title='Expand category'
                    />
                  </Label>
                </div>
                <CSSTransition in={isExpanded} timeout={300} classNames={`${reducedMotionClass} expanded`}>
                  <div className={animationStyles}>
                    {this.props.popularCategories[key].map(({ service_group_name, list_slug }) => (
                      <div key={list_slug} className={css`margin-bottom: 13px;`}>
                        <Link4Inline
                          tabIndex='1'
                          href={list_slug}
                        >
                          {service_group_name}
                        </Link4Inline>
                      </div>
                    ))}
                  </div>
                </CSSTransition>
              </CategoryWrapper>
            )})}
        </DropDown>
      </div>
    );
  }
}

export default PopularCategories;

// partials

const DropDown = styled(DataBox)`
  position: absolute;
  left: 10px;
  background-color: var(--ucGhosted);
  padding: 25px 15px 10px 15px;
  margin-top: 10px;
  z-index: 3;
  display: ${props => props.display || ''};
  min-width: 270px;
`;

const ExpandIcon = styled(ArrowDown)`
  height: 15px;
  transition: ${props => !props.reducedMotion ? 'all 0.15s 0.20s' : ''};
  transform: ${props => props.expanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${props => props.linkColor || 'var(--ucGhosted)'};
  svg { margin-left: 10px; display: block; }
  :hover { opacity: 0.6; }
`;

const CategoryWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  display: grid;
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--ucDarkBlue80);
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 14px;
  z-index: 2;
  position: relative;
  background-color: var(--ucGhosted);
  width: 100%;
`;
