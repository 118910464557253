import React from 'react';
import { styled } from '@linaria/react';

const Link2 = props => {
  let Wrapper = 'a';
  if (props.onClick) Wrapper = 'div';
  return (
    <Wrapper
      {...props}
      tabIndex={props.tabIndex || '0'}
      className={`${props.className} tactile-animation`}
    >
      <span className='text'>{props.children}</span>
    </Wrapper>
  );
}

export default styled(Link2)`
  color: var(--ucBlue);
  cursor: pointer;
  display: ${props => props.display ||'inline-block'};
  font-weight: ${props => props.fontWeight || 600};
  font-size: ${props => props.fontSize || ''};
  text-decoration: none;
  width: fit-content;

  .text {
    display: inline-block;
    line-height: 1;
    padding-bottom: 2px;
    border-bottom: 3px solid var(--ucBlue);
    transition: margin-bottom 300ms ease-in-out, padding-bottom 300ms ease-in-out, border-bottom 300ms ease-in-out;
  }

  &:hover {
    .text {
      padding-bottom: 0px;
      margin-bottom: 2px;
      color: var(--ucBlue);
    }
  }
`;
