import { styled } from '@linaria/react';
import media from '../utils/mediaQueries';

export const P = styled.p`
  line-height: 1.65;
  font-weight: 400;
`;

export const SmallP = styled(P)`
  font-size: .9rem;
  font-weight: ${props => props.fontWeight || '400'};
`;

export const XSmallP = styled.small`
  font-size: .8rem;
  line-height: 1.65;
  font-weight: ${props => props.fontWeight || '400'};
`;

export const Strong = styled.strong`
  font-weight: 600;
`;

export const Stronger = styled.strong`
  font-weight: bolder;
`;

export const H1Span = styled.h1`
  font-size: 1rem;
  line-height: 1.65;
  text-transform: uppercase;
  font-weight: 700;
  min-height: 42px;
  margin-top: 35px;
  color: var(--ucDarkBlue75);
  text-transform: uppercase;
  display: block;
  ${media.smMax} { margin-top: 20px; }
`;

export const H1 = styled.h1`
  font-size: 2rem;
  line-height: 1.5;
`;

export const H4 = styled.h4`
  font-size: 1rem;
  line-height: 1.65;
`;
