import React from 'react';
import { css } from '@linaria/core';
import bestPlaceBadge from '../../../../../images/local-marketing-agencies/best-place-to-work.png';
// static list data
import { list1, list2, list3, list4 } from '../lists';
import List from '../components/List';
import SocialMediaIcon from '../components/SocialMediaIcon';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { maxWidthStyles } from '../../component-library/styles/maxWidthStyles';
// assets
import logo from '../../../../../images/local-marketing-agencies/logos/corporate-logo/UpCity-Logo-inverseLight.svg';

const Footer = props => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div
      id='footer-container'
      className={css`
        padding: 30px 0;
        background-color: var(--ucDarkBlue);
      `}
    >
      <div
        className={css`
          display: flex;
          color: #fff;
          flex-wrap: wrap;
          margin: 0 auto;
          ${maxWidthStyles};
        `}
      >
        <div className={css`padding: 30px 30px 0 0;`}>
          <a href={props.requestOrigin}>
            <LazyLoadComponent>
              <img src={logo} alt='UpCity Logo' width='110px' />
            </LazyLoadComponent>
          </a>
          <LazyLoadComponent>
          <div className={css`padding: 30px 30px 0 0; white-space: nowrap;`}>
            <SocialMediaIcon icon='facebook' href='https://www.facebook.com/upcityinc' />
            <SocialMediaIcon icon='linked_in' href='https://www.linkedin.com/company/upcity' />
            <SocialMediaIcon icon='x' href='https://twitter.com/upcityinc' />
          </div>
            <a href='/blog/upcity-named-a-2021-inc-best-workplace/' target='_blank' className={css`display: block; padding: 30px 0;`}>
              <img src={bestPlaceBadge} alt='Visual of Inc. Best Workplace 2021 Badge' width='130px' className={css`display: block; `} />
            </a>
          </LazyLoadComponent>
        </div>
        <List lists={[list1]} />
        <List lists={[list2]} />
        <List lists={[list3]} />
        <List lists={[list4]} />
        <div className={css`width: 100%; padding: 30px 0; color: var(--ucGhosted); font-size: 12px;`}>@ {year} UpCity. All Rights Reserved.</div>
      </div>
    </div>
  );
}

export default React.memo(Footer);
