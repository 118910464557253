import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@linaria/react';
import { cx, css } from '@linaria/core';
import PopularCategories from '../components/PopularCategories';
import Hamburger from '../../shared/Hamburger';
import PrimaryBtnBase from '../../component-library/components/buttons/PrimaryBtnBase';
import { hideOnMdScreen } from '../../component-library/styles/display';
import media from '../../component-library/utils/mediaQueries';
import logo from '../../../../../images/local-marketing-agencies/logos/corporate-logo/UpCity-Logo-Primary.svg';
import logoPin from '../../../../../images/local-marketing-agencies/logos/logo-pin.svg';
import { SearchInput } from '../../component-library/components/formComponents/SearchInput';


const Header = props => {
  return (
    <Wrapper className={props.className}>
      <LogoWrapper>
        <a href={props.requestOrigin}>
          <img src={logo} className={currentLogo} alt='UpCity Logo' title='UpCity Logo' />
          <img src={logoPin} className={orangePinLogo} alt='UpCity Logo' title='UpCity Logo' />
        </a>
        <SearchInput toggleMobileSearchInput={props.toggleMobileSearchInput} search_url={props.search_url} />
      </LogoWrapper>
      <FlexWrapper>
        <PopularCategories className={cx(hideOnMdScreen, space)} popularCategories={props.popularCategories} linkColor='var(--ucDarkBlue' />
        <Link className={cx(space, hideOnMdScreen)} href='/providers'>Who is UpCity?</Link>
        <StyledPrimaryBtnBase className={getStartedBtnStyles} colorCode='darkBlue' href='/providers#join' title='Get Listed!'>Get Listed!</StyledPrimaryBtnBase>
        <Link className={cx(space, hideOnMdScreen, logInLink)} href='https://insights.upcity.com' target='_blank'>Log in</Link>
        <Hamburger onClick={props.toggleSidebarMenu} />
      </FlexWrapper>
    </Wrapper>
  )
}

Header.propTypes = {
  toggleSidebarMenu: PropTypes.func.isRequired,
  // toggleMobileSearchInput: PropTypes.func.isRequired,
  popularCategories: PropTypes.object.isRequired,
  requestOrigin: PropTypes.string.isRequired,
};

export default Header;

// partials

const getStartedBtnStyles = css`
  ${media.smMax} {
    margin: 0 10px;
    padding: 10px;
  }
`;

const orangePinLogo = css`
  ${media.SearchInputExtraSmallDevices} {
    display: block;
  }

  ${media.SearchInputSmallDevices} {
    display: none;
  }

  height: 44px;
  vertical-align: middle;
`;

const currentLogo = css`
  ${media.SearchInputExtraSmallDevices} {
    display: none;
  }
  ${media.xsMin} {
    display: block;
  }
`;

const space = css`
  margin: 0 12px;
  :hover {opacity: 0.6;}

  ${media.lgDevices} {
    margin: 0 20px;
  }
`;

const FlexWrapper = styled.div`
  flex: 8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  ${media.mdMax} {
    flex: 2;
  }
`;

const Wrapper = styled(FlexWrapper)`
  justify-content: space-between;
  ${media.navOnly} {
    font-size: 0.9rem;
    a {
      font-size: 0.9rem;
    }
  }
  ${media.lgDevices} {
    font-size: 1rem;
    a {
      font-size: 1rem;
    }
  }
`;

const Link = styled.a`
  font-weight: 400;
  color: var(--ucDarkBlue);
  text-decoration: none;
`;

const StyledPrimaryBtnBase = styled(PrimaryBtnBase)`
  ${media.smMax} { width: auto; }
`;

const LogoWrapper = styled.div`
  flex: 6;
  display: flex;
  align-items: center;
  flex-direction: row;
  a {
    display: inline-block;
    max-width: 134px;
  }
  img { width: 100%; min-width: 130px; }

  ${media.mdMax} {
    flex: 5;
    gap: 10px;
  }

  ${media.SearchInputExtraSmallDevices} {
    img {
      width: 44px;
      min-width: 44px;
    }
  }
`;

const logInLink = css`
  ${media.mdMin} {
    margin: 0 0 0 12px!important;
  }
`;
