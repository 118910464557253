import React, { useState, useEffect } from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import MainWrapper from '../../component-library/components/MainWrapper';
import Link3 from '../../component-library/components/links/Link3';
import media from '../../component-library/utils/mediaQueries';
import Header from '../../header/containers/index';
import Footer from '../../footer/containers/index';
import SideBarMenu from '../../shared/SideBarMenu';
import { H1 } from '../../component-library/components/Typography';
import { maxWidthStyles } from '../../component-library/styles/maxWidthStyles';
import { reducedMotionUserSetting, scrollToElement } from '../../../utilities/helpers';
import { MobileSearchInput } from '../../shared/MobileSearchInput/MobileSearchInput';

const NotFound = props => {
  const [reducedMotion, setReducedMotion] = useState(false);
  const [showSidebarMenu, setShowSidebarMenu] = useState(false);
  const [showMobileSearchInput, setShowMobileSearchInput] = useState(false);

  const toggleMobileSearchInput = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vw > media.breakpoints.sm) {
      setTimeout(function () {
        scrollToElement('html');
      },700);
    } else {
      setShowMobileSearchInput(!showMobileSearchInput);
    }
  };

  const toggleSidebarMenu = () => {
    window.scrollTo(0, window.scrollY + 1);
    setTimeout(() => setShowSidebarMenu(!showSidebarMenu), 500);
  };

  useEffect(() => {
    const isReduced = reducedMotionUserSetting();
    if (isReduced) setReducedMotion(isReduced);
  }, reducedMotion);

  return (
    <>
        <MobileSearchInput
          showMobileSearchInput={showMobileSearchInput}
          toggleMobileSearchInput={toggleMobileSearchInput}
          search_url={props.search_url}
        />
      <MainWrapper className={css`min-height: auto;`}>
        <SideBarMenu
          requestOrigin={props.request_origin}
          popularCategories={props.popular_categories}
          toggleSidebarMenu={toggleSidebarMenu}
          showSidebarMenu={showSidebarMenu}
          toggleMobileSearchInput={toggleMobileSearchInput}
        />
        <Header
          className={css`${maxWidthStyles}; margin: 5px auto;`}
          reducedMotion={reducedMotion}
          popularCategories={props.popular_categories}
          requestOrigin={props.request_origin}
          toggleSidebarMenu={toggleSidebarMenu}
          showMobileSearchInput={showMobileSearchInput}
          toggleMobileSearchInput={toggleMobileSearchInput}
          search_url={props.search_url}
        />
        <Wrapper>
          <H1><span className='red'>404 Error</span>&#8212;Page Doesn't Exist</H1>
          <p>
            Uh oh! Looks like the page you were looking for doesn’t meet UpCity’s credibility standards. See how serious we are? 
          </p>
          <p>
            Okay, okay—all jokes aside, let’s get you back on track with finding <Link3 href={`${props.request_origin}lists`} fontWeight='600'>a service provider</Link3>.
          </p>
        </Wrapper>
      </MainWrapper>
      <Footer />
    </>
  );
}

export default NotFound;

// partials

const Wrapper = styled.div`
  max-width: 550px;
  margin: 200px auto;
  p { font-weight: 500; }
  .red {
    color: var(--ucRed);
    font-size: inherit;
    text-transform: inherit;
  }
  ${media.smMax} {
    margin: 50px auto;
    max-width: 80%;
  }
`;
