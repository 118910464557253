import { styled } from '@linaria/react';

const IconWrapper = styled.div`
  background-color: ${props => props.backgroundColor || 'transparent' };
  border-radius: 50%;
  min-width: ${props => props.iconWrapperMinWidth || '36px'};
  width: ${props => props.iconWrapperWidth || '36px'};
  height: ${props => props.iconWrapperHeight || '36px'};
  display: flex;
  align-items: center;
  justify-content: center;
  svg { pointer-events: none; }
`;

export default IconWrapper;
