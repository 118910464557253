import React, { useState } from 'react'
import { css } from '@linaria/core';
import { SearchInputMobile } from './SearchInputMobile';
import { useMediaQuery } from 'react-responsive';

export const MobileSearchInput = (props) => {

  const isMobile = useMediaQuery({ maxWidth: 750 });

  return (
    <>
      {
        props.showMobileSearchInput && isMobile &&
        <>
          <SearchInputMobile className={inputMobile} toggleMobileSearchInput={props.toggleMobileSearchInput} search_url={props.search_url} />
          <div className={overlay} onClick={props.toggleMobileSearchInput} ></div>
        </>
      }
    </>
  )
}


const inputMobile = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

const overlay = css`
  width: 100vw;
  height: 100vh;
  margin: 55px 0 0 12px;
  padding: 10px;
  position: fixed;
  background-color: rgba(250, 250, 250, 0.85);
  top: 0;
  left: -12.5px;
  right: 0;
  bottom: 0;
  z-index: 1001;

  .overlay .close-overlay {
    position: absolute;
    right: 3rem;
    top: 3rem;
    width: 3rem;
    height: 3rem;
  }

  .overlay .close-overlay img {
    width: 100%;
  }
  
  .overlay .form {
    max-width: 80rem;
    width: 40rem;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  .overlay label {
    color: var(--blanco);
  }
  body .overlay {
    overflow: hidden;
  }
  
  .overlay .form.animation {
    position: relative;
    opacity: 1;
  }
  
  .overlay .form.close {
    opacity: 0;
  }
  
  .overlay label {
    text-align: left;
  }
`;